import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import ProcessItem from './workingProcess.style';
import { Link } from 'gatsby';
import Button from '../../../../common/src/components/Button';
import { useTranslation } from 'react-i18next';

const WorkingProcessSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
  processRow,
  processCol,
  processImageStyle,
  processTitleStyle,
  processDescriptionStyle,
  buttonUrl,
  title,
  description,
  items,
  pageContext,
}) => {
  const { t } = useTranslation();
  return (
    <Box {...sectionWrapper} as="section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content={title} />
          <Heading {...secHeading} content={description} />
        </Box>

        <Box {...processRow}>
          {items.map((item, index) => (
            <Box
              {...processCol}
              key={`process-item-${index}`}
              className="process_item_col"
            >
              <ProcessItem
                className="process_item"
                style={{ textAlign: 'center' }}
              >
                {item.image && (
                  <Image
                    src={item.image.publicURL}
                    alt={t(item.alt)}
                    {...processImageStyle}
                  />
                )}
                <Heading
                  as="h3"
                  content={t(item.title)}
                  {...processTitleStyle}
                />
                <Text
                  content={t(item.description)}
                  {...processDescriptionStyle}
                />
              </ProcessItem>
            </Box>
          ))}
        </Box>
        {buttonUrl && (
          <div style={{ textAlign: 'right' }}>
            <Link to={`${pageContext.langPathPrefix}${buttonUrl}`}>
              <Button title={t('All Features')} />
            </Link>
          </div>
        )}
      </Container>
    </Box>
  );
};

WorkingProcessSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  processRow: PropTypes.object,
  processCol: PropTypes.object,
  processImageStyle: PropTypes.object,
  processTitleStyle: PropTypes.object,
  processDescriptionStyle: PropTypes.object,
  buttonUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.array,
};

WorkingProcessSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '15px', '15px', '15px', '15px'],
    pb: 0,
  },
  secTitleWrapper: {
    mb: ['60px', '100px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  processRow: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['0', '-15px', '-20px', '-35px', '-45px'],
    mr: ['0', '-15px', '-20px', '-35px', '-45px'],
  },
  processCol: {
    width: [1, 1 / 3],
    pl: ['0', '15px', '20px', '35px', '45px'],
    pr: ['0', '15px', '20px', '35px', '45px'],
    mb: '40px',
  },
  processImageStyle: {
    mb: '35px',
    height: ['60px', '60px', '70px', '100px'],
    display: 'inline',
    paddingBottom: ['10px', '20px', '20px', '20px'],
  },
  processTitleStyle: {
    fontSize: ['20px', '18px', '20px', '20px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.02em',
    mb: ['20px', '20px', '22px', '22px', '22px'],
  },
  processDescriptionStyle: {
    fontSize: ['15px', '15px', '15px', '15px', '15px'],
    color: '#343d48cc',
    lineHeight: '2',
    mb: '33px',
  },
};

export default WorkingProcessSection;
