import styled from 'styled-components';
import BannerBG from 'common/src/assets/image/saasModern/banner-texture.png';

export const BannerWrapper = styled.section`
  padding: 150px 0 150px 0;
  background-image: url(${BannerBG}),
    linear-gradient(35deg, rgb(147, 249, 185) 0%, rgb(47, 72, 88) 100%);
  background-size: cover;
  background-position: top center;
  @media (max-width: 575px) {
    padding: 90px 0 0 0;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`;

export const DiscountWrapper = styled.div`
  text-align: center;
`;

export const DiscountLabel = styled.div`
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  border-radius: 4em;
  padding: 9px 22px;
  margin-bottom: 22px;
  background-color: rgba(255, 255, 255, 0.15);
  @media (max-width: 575px) {
    padding: 7px 10px;
  }
`;

export const TestimonialWrapper = styled.div`
  background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  padding: 20px;
  text-align: center;
  width: 80%;
  margin: auto;
`;

export const BannerItem = styled.div`
  display: inline-block;
  margin: 0 20px;
  position: relative;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

export const BannerLink = styled.a`
  text-decoration: none;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

export const Logo = styled.img`
  height: 24px !important;
  margin-right: 8px;
`;

export const Star = styled.span`
  color: gold;
`;

export const GrayStar = styled.span`
  color: lightgray;
`;
