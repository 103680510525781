import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';

const TextBoxSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
  processRow,
  title,
  text,
  description,
  textWall,
}) => {
  return (
    <Box {...sectionWrapper} as="section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content={title} />
          <Heading {...secHeading} content={description} />
        </Box>

        <Box {...processRow}>
          <Text {...textWall} content={text} />
        </Box>
      </Container>
    </Box>
  );
};

TextBoxSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  processRow: PropTypes.object,
  textWall: PropTypes.object,
  title: PropTypes.string,
  text: PropTypes.string,
  description: PropTypes.string,
};

TextBoxSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '15px', '15px', '15px', '15px'],
    pb: 0,
  },
  secTitleWrapper: {
    mb: ['30px', '60px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  textWall: {
    fontSize: ['15px', '15px', '15px', '15px', '15px'],
    color: '#343d48cc',
    lineHeight: '2',
    mb: '33px',
  },
  processRow: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['0', '-15px', '-20px', '-35px', '-45px'],
    mr: ['0', '-15px', '-20px', '-35px', '-45px'],
  },
};

export default TextBoxSection;
