import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';

import axios from 'axios';

import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';

import PricingTable, {
  PricingHead,
  PricingContent,
  PricingPrice,
  PricingButton,
  PricingList,
  ListItem,
  PricingButtonWrapper,
} from './pricing.style';

import { checkmark } from 'react-icons-kit/icomoon/checkmark';
import { useTranslation } from 'react-i18next';

const PricingSection = ({
  sectionWrapper,
  secTitleWrapper,
  secHeading,
  secText,
  nameStyle,
  descriptionStyle,
  priceStyle,
  priceTitleStyle,
  priceLabelStyle,
  buttonStyle,
  buttonFillStyle,
  listContentStyle,
  listContentStyleLink,
  sectionInfo,
}) => {
  const { t } = useTranslation();
  const [pricing, setPricing] = useState(null);
  const [state, setState] = useState('onpremise');

  const loadPricing = () => {
    axios
      .get('/api/pricing')
      .then((result) => {
        setPricing(result.data);
      })
      .catch((err) => {
        setPricing({
          psono_ce: 'N/A',
          psono_ee_limited: 'N/A',
          psono_ee: 'N/A',
          psono_saas: 'N/A',
        });
      });
  };
  React.useEffect(() => {
    loadPricing();
  }, []);

  return (
    <Box {...sectionWrapper} id="pricing">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content={t('PRICING PLAN')} />
          <Heading
            {...secHeading}
            content={t('An overview of all our editions')}
          />
          <PricingButtonWrapper>
            <Button
              title={t('On Premise')}
              className={state === 'onpremise' ? 'active-item' : ''}
              onClick={() => setState('onpremise')}
            />
            <Button
              title={t('SaaS')}
              className={state === 'saas' ? 'active-item' : ''}
              onClick={() => setState('saas')}
            />
          </PricingButtonWrapper>
        </Box>
        <Box>
          <PricingContent
            columnCount={
              sectionInfo.filter(
                (pricingTable) => pricingTable.hostingType === state
              ).length
            }
          >
            {pricing != null && (
              <>
                {sectionInfo
                  .filter((pricingTable) => pricingTable.hostingType === state)
                  .map((pricingTable, index) => (
                    <PricingTable
                      key={`pricing-table-${index}`}
                      freePlan={pricingTable.freePlan}
                      className="pricing_table"
                    >
                      <PricingHead>
                        <img
                          src={pricingTable.titleImage.publicURL}
                          alt={`version-title-${index}`}
                          style={priceTitleStyle}
                        />
                        <Text
                          content={t(pricingTable.description)}
                          {...descriptionStyle}
                        />
                      </PricingHead>
                      <PricingPrice>
                        <Text
                          content={pricing[pricingTable.id]}
                          {...priceStyle}
                        />
                        <Text
                          content={t(pricingTable.priceLabel)}
                          {...priceLabelStyle}
                        />
                      </PricingPrice>
                      <PricingButton>
                        <a
                          href={pricingTable.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {pricingTable.freePlan ? (
                            <Button
                              title={t(pricingTable.buttonLabel)}
                              {...buttonStyle}
                            />
                          ) : (
                            <Button
                              title={t(pricingTable.buttonLabel)}
                              {...buttonFillStyle}
                            />
                          )}
                        </a>
                      </PricingButton>
                      <PricingList>
                        {pricingTable.listItems.map((item, index) => (
                          <ListItem key={`pricing-table-list-${index}`}>
                            {item.content && (
                              <Icon
                                icon={checkmark}
                                className="price_list_icon"
                                size={13}
                              />
                            )}
                            {item.link ? (
                              <a href={item.link} className="ListItem">
                                <Text
                                  content={t(item.content) || '\u00A0'}
                                  {...listContentStyleLink}
                                />
                              </a>
                            ) : (
                              <Text
                                content={t(item.content) || '\u00A0'}
                                {...listContentStyle}
                              />
                            )}
                          </ListItem>
                        ))}
                      </PricingList>
                    </PricingTable>
                  ))}
              </>
            )}
          </PricingContent>
        </Box>
        <div style={{ textAlign: 'right', marginTop: '10px' }}>
          *{' '}
          {t(
            'ask if you require professional support or SLAs and we provide you an offer'
          )}
        </div>
      </Container>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  col: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceTitleStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
  listContentStyleLink: PropTypes.object,
  sectionInfo: PropTypes.array,
};

PricingSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['60px', '100px', '100px', '140px', '160px'],
    pb: ['60px', '80px', '80px', '100px'],
  },
  secTitleWrapper: {
    mb: ['50px', '75px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  nameStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: 'headingColor',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em',
  },
  priceTitleStyle: {
    display: 'block',
    margin: 'auto',
    maxWidth: '100%',
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    bg: '#fff',
    color: '#2aa275',
    colors: 'primaryWithBg',
    width: '222px',
    maxWidth: '100%',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primaryWithBg',
    width: '200px',
    maxWidth: '100%',
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    mb: '0',
  },
  listContentStyleLink: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    style: {
      textDecoration: 'underline',
    },
    mb: '0',
  },
};

export default PricingSection;
